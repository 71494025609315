import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {

    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) {

    }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            //new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'flaticon-line-graph', '/app/admin/hostDashboard'),
            //new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'flaticon-line-graph', '/app/main/dashboard'),
            //new AppMenuItem('Clients', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'),
            //new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),            
            //new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
            //new AppMenuItem('Dashboard', 'Pages.Dashboard', 'leftmenu dashboardIcon', '/app/main/dashboard'),
            //new AppMenuItem('Activities', 'Pages.Activity', 'leftmenu activitesIcon', '/app/main/activitydashboard'),
            new AppMenuItem('Brands', 'Pages.Brand', ' leftmenu brandsIcon', '/app/main/branddashboard'),
            new AppMenuItem('Patient Database', 'Pages.Speakers', 'leftmenu kol-insights', '/app/speaker/brandselection'),         
            //new AppMenuItem('Advisor Database', 'Pages.AdvisorDatabase', 'leftmenu advisorIcon', '/app/main/advisordashboard'),
            new AppMenuItem('User Management', 'Pages.UserManagement', 'leftmenu user-managementIcon', '/app/main/usermanagement'),
            new AppMenuItem('Client Management', 'Pages.Clients', 'leftmenu client-managementIcon', '/app/main/editclientdashboard'),
            //new AppMenuItem('Activities', 'Pages.AdvisorActivity', 'leftmenu activitesIcon', '/app/main/advisoractivity'),            
            //new AppMenuItem('Additional Materials', 'Pages.AdditionalMaterials', 'leftmenu brandsIcon', '/app/main/addmatmaindash'),
            //new AppMenuItem('Additional Materials', 'Pages.AdvisorAdditionalMaterials', 'leftmenu brandsIcon', '/app/main/additionalmaterials'),
            //Advisor profile
            //new AppMenuItem('My Profile', 'Pages.AdvisorMyProfile', 'leftmenu my-profileIcon', '/app/main/advisorprofile'),

            //User profile
            new AppMenuItem('My Profile', 'Pages.UserProfile', 'leftmenu my-profileIcon', '/app/main/myuserprofile'),  
             //new AppMenuItem('Patient Insights', 'Pages.PatientInsights', 'leftmenu brandsIcon', '/app/main/patientinsights'),   
            new AppMenuItem('Roles', 'Pages.Administration.Roles', 'leftmenu flaticon-suitcase', '/app/admin/roles'),
            //new AppMenuItem('Administration', '', 'flaticon-interface-8', '', [
            //    new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'flaticon-map', '/app/admin/organization-units'),
            //    new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
            //    new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
            //    new AppMenuItem('Languages', 'Pages.Administration.Languages', 'flaticon-tabs', '/app/admin/languages'),
            //    new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'flaticon-folder-1', '/app/admin/auditLogs'),
            //    new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'flaticon-lock', '/app/admin/maintenance'),
            //    new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
            //    new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
            //    new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'flaticon-settings', '/app/admin/hostSettings'),
            //    new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'flaticon-settings', '/app/admin/tenantSettings')
            //]),
            
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {

        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];
            if (subMenuItem.permissionName && this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            } else if (subMenuItem.items && subMenuItem.items.length) {
                return this.checkChildMenuItemPermission(subMenuItem);
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {    
        if (menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' && this._appSessionService.tenant && !this._appSessionService.tenant.edition) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            console.log("84848484848484848484484848448" + menuItem.permissionName);
            hideMenuItem = true;
        }
        
        let userId =localStorage.getItem("userId");
        let userRole = localStorage.getItem("userRole");
        if(userRole != 'superadmin' && menuItem.permissionName == "Pages.UserManagement")
        {
            hideMenuItem = true;
        }
        if(userRole != 'superadmin' && menuItem.permissionName == "Pages.Clients")
        {
            hideMenuItem = true;
        }

        console.log("menuItem.permissionName " + userId);
        console.log(menuItem.permissionName);

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }
}
