import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'degree'
})
export class DegreePipe implements PipeTransform {

  transform(degreeList: Array<any>, degreeId: any): any {
    let index = degreeList.findIndex((e1)=>{
      return e1.id == degreeId;
    })
    if(index > -1){
      return degreeList[index].description;
    }
    return null;
  }

}
