import { Injector, Component, OnInit, ViewEncapsulation, Inject, ChangeDetectorRef } from '@angular/core';
import { AbpMultiTenancyService } from '@abp/multi-tenancy/abp-multi-tenancy.service';
import { AbpSessionService } from '@abp/session/abp-session.service';
import { ImpersonationService } from '@app/admin/users/impersonation.service';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { LinkedAccountService } from '@app/shared/layout/linked-account.service';
import { AppConsts } from '@shared/AppConsts';
import { ThemesLayoutBaseComponent } from '@app/shared/layout/themes/themes-layout-base.component';
import { ChangeUserLanguageDto, LinkedUserDto, ProfileServiceProxy, UserLinkServiceProxy } from '@shared/service-proxies/service-proxies';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import {AppUrlService} from '../../../shared/common/nav/app-url.service'

@Component({
  templateUrl: './mobile-top-nav.component.html',
  styleUrls: ['./mobile-top-nav.component.css'],
  selector: 'mobile-top-nav',
  encapsulation: ViewEncapsulation.None
})
export class mobileTopNavComponent extends ThemesLayoutBaseComponent implements OnInit {

  
  public isMenuCollapsed = true;  

  isHost = false;
  languages: abp.localization.ILanguageInfo[];
  currentLanguage: abp.localization.ILanguageInfo;
  isImpersonatedLogin = false;
  isMultiTenancyEnabled = false;
  shownLoginName = '';
  tenancyName = '';
  userName = '';
  profilePicture = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
  defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/app-logo-on-' + this.currentTheme.baseSettings.menu.asideSkin + '.svg';
  recentlyLinkedUsers: LinkedUserDto[];
  unreadChatMessageCount = 0;
  remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;
  chatConnected = false;
  isQuickThemeSelectEnabled: boolean = this.setting.getBoolean('App.UserManagement.IsQuickThemeSelectEnabled');
  clientLogo: any;
  siteLogo: any = AppConsts.appBaseUrl + '/assets/common/images/iQueueLogo.png'
  isShowLogo: boolean = false;
  isClientManagement: boolean = false;
  isHomeClientMgnt:boolean = false;
  userRole:any;
  isSuperAdmin:boolean=false;
  constructor(
    injector: Injector,
    public appUrlService:AppUrlService,
    private _abpSessionService: AbpSessionService,
    private _abpMultiTenancyService: AbpMultiTenancyService,
    private _profileServiceProxy: ProfileServiceProxy,
    private _userLinkServiceProxy: UserLinkServiceProxy,
    private _authService: AppAuthService,
    private _impersonationService: ImpersonationService,
    private _linkedAccountService: LinkedAccountService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(injector);
  }
  ngAfterViewChecked() {

    if (this.router.url.indexOf('clientmanagement') !== -1) {
      this.isHomeClientMgnt = true;
      this.isShowLogo = true;
      this.document.getElementById("pageHeader").classList.add('mainPadding');
      this.document.getElementById("selectedClientLogo").setAttribute('style', 'display:none !important');
    }
    else if (this.router.url.indexOf('isFromClient') !== -1) {
      this.isHomeClientMgnt = false;
      this.isShowLogo = true;
      this.isClientManagement = true;
      this.document.getElementById("navId").classList.add('m--padding-right-55');
      this.document.getElementById("pageHeader").classList.add('mainPadding');
      this.document.getElementById("selectedClientLogo").setAttribute('style', 'display:none !important');
    }
    else if (this.router.url.indexOf('createclient') !== -1) {
      this.isHomeClientMgnt = false;
      this.isShowLogo = false;
      this.document.getElementById("pageHeader").classList.add('mainPaddingLeft');
      this.document.getElementById("selectedClientLogo").setAttribute('style', 'display:inline-block !important');
    }
    else {
      this.isHomeClientMgnt = false;
      this.isShowLogo = false;
      this.document.getElementById("pageHeader").classList.add('mainPaddingLeft');
      this.document.getElementById("selectedClientLogo").setAttribute('style', 'display:inline-block !important');
      this.document.getElementById('m_header_nav').style.top = 'unset';
      this.document.getElementById('main-wrapper').style.paddingLeft = '100px !important';
      this.document.getElementById('main-wrapper').classList.remove('pl-0');
    }
      this.userRole = localStorage.getItem('userRole');
      if(this.userRole=='superadmin'){
        this.isSuperAdmin =true;
      }
      else{
        if (this.router.url.indexOf('dashboard') > -1 && (this.userRole=='admin' || this.userRole=='client' || this.userRole=='Client / Executive')){
            this.isHomeClientMgnt= true;
        }
      }

      let brandLogo =  localStorage.getItem("brandLogo");
      if (brandLogo !== this.clientLogo) { 
        this.clientLogo = brandLogo;
        this.cdRef.detectChanges();
      }
  }

  ngOnInit() {
    //this.appUrlService.currentHeaderUserRole.subscribe((result:any)=>{
      this.userRole = localStorage.getItem('userRole');
      if(this.userRole=='superadmin'){
        this.isSuperAdmin =true;
      }
      else{
        if (this.router.url.indexOf('dashboard') > -1 && (this.userRole=='admin' || this.userRole=='client' || this.userRole=='Client / Executive')){
            this.isHomeClientMgnt= true;
        }
      }
      
    //})
       
    this.isHost = !this._abpSessionService.tenantId;
    this.isMultiTenancyEnabled = this._abpMultiTenancyService.isEnabled;
    this.languages = _.filter(this.localization.languages, l => (l).isDisabled === false);
    this.currentLanguage = this.localization.currentLanguage;
    this.isImpersonatedLogin = this._abpSessionService.impersonatorUserId > 0;
    this.setCurrentLoginInformations();
    
    this.registerToEvents();
    if (this.router.url.indexOf('clientmanagement') > -1) {
      this.isClientManagement = true;
      this.isHomeClientMgnt = true;
    }
    else {
      this.isClientManagement = false;
      this.isHomeClientMgnt = false;
    }
  }

  registerToEvents() {
    abp.event.on('app.chat.unreadMessageCountChanged', messageCount => {
      this.unreadChatMessageCount = messageCount;
    });

    abp.event.on('app.chat.connected', () => {
      this.chatConnected = true;
    });
  }

 

  setCurrentLoginInformations(): void {
    this.shownLoginName = this.appSession.getShownLoginName();
    this.tenancyName = this.appSession.tenancyName;
    this.userName = this.appSession.user.userName;
  }

  getShownUserName(linkedUser: LinkedUserDto): string {
    if (!this._abpMultiTenancyService.isEnabled) {
      return linkedUser.username;
    }

    return (linkedUser.tenantId ? linkedUser.tenancyName : '.') + '\\' + linkedUser.username;
  }

  showLoginAttempts(): void {
    abp.event.trigger('app.show.loginAttemptsModal');
  }

  showLinkedAccounts(): void {
    abp.event.trigger('app.show.linkedAccountsModal');
  }

  changePassword(): void {
    abp.event.trigger('app.show.changePasswordModal');
  }

  changeProfilePicture(): void {
    abp.event.trigger('app.show.changeProfilePictureModal');
  }

  changeMySettings(): void {
    abp.event.trigger('app.show.mySettingsModal');
  }

  logout(): void {
    this._authService.logout();
    localStorage.clear();
  }
  
}
