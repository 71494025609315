import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-piegraphgrid',
  templateUrl: './piegraphgrid.component.html',
  styleUrls: ['./piegraphgrid.component.css']
})
export class PiegraphgridComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
