import { Injectable } from '@angular/core';

@Injectable()
export class GlobalComponent {
   
    constructor() { }
   
    selectMenu(selected: string):void{
        var menu= document.getElementById('m_aside_left').getElementsByTagName("li");
        for (let index = 0; index < menu.length; index++) {
          //const element = array[index];
          if(menu[index].innerText.trim() == selected.trim()) 
            menu[index].classList.add('m-menu__item--active');
          else
            menu[index].classList.remove('m-menu__item--active');
        }
        document.getElementById('m_aside_left').getElementsByTagName("li")[0].innerText
      }
}

