import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'speciality'
})
export class SpecialityPipe implements PipeTransform {

  transform(specialityList: Array<any>, specId:any): any {
    let index = specialityList.findIndex((e1)=>{
      return e1.id == specId;
    })
    if(index > -1){
      return specialityList[index].description;
    }
    return null;
  }

}
