import { Injectable, Input } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class LayoutRefService {
    layoutRefs$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    layoutRefs: any = {};

    addElement(name, element) {
        const obj = {};
        obj[name] = element;
        this.layoutRefs$.next(Object.assign(this.layoutRefs, obj));
    }

    // Observable sources
    private LeftMenuVisibleSource = new Subject<boolean>();
    private ClientLogoVisibleSource = new Subject<boolean>();

    // Observable string streams
    LeftMenuVisible$ = this.LeftMenuVisibleSource.asObservable();
    ClientLogoVisible$ = this.ClientLogoVisibleSource.asObservable();

    private _leftMenuVisible: boolean = true;
    get leftMenuVisible(): boolean {
        return this._leftMenuVisible;
    }

    @Input()
    set leftMenuVisible(value: boolean) {
        this.LeftMenuVisibleSource.next(value);
    }

    private _showClientLogo: boolean = false;
    get showClientLogo(): boolean {
        return this._showClientLogo;
    }

    @Input()
    set showClientLogo(value: boolean) {
        this.ClientLogoVisibleSource.next(value);
    }
}
