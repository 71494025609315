import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'state'
})
export class StatePipe implements PipeTransform {

  transform(stateList: Array<any>, stateId: any): any {
    let index = stateList.findIndex((e1)=>{
      return e1.id == stateId;
    })
    if(index > -1){
      return stateList[index].description;
    }
    return null;
  }

}
