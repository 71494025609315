import { Component, Input, OnInit } from '@angular/core';
import { CountryStateCitiesDto, CodeName, CountryDto, GeoDataServiceServiceProxy } from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-countrystatescities',
  templateUrl: './countrystatescities.component.html',
  styleUrls: ['./countrystatescities.component.css']
})
export class CountrystatescitiesComponent implements OnInit {
  cityList: CodeName[];
  countryList: CountryDto[];
  stateList: CodeName[];
  isdataloading: boolean;
  @Input() isMandatory: boolean;

  constructor(private _geoDataService: GeoDataServiceServiceProxy) { }

  ngOnInit() {
    this.getCountries();
  }

  private _countryStateCitiesDetail: CountryStateCitiesDto;
  get countryStateCitiesDetail(): CountryStateCitiesDto {
    return this._countryStateCitiesDetail;
  }

  @Input()
  set countryStateCitiesDetail(value: CountryStateCitiesDto) {
    this._countryStateCitiesDetail = value;
    if (this._countryStateCitiesDetail != null) {
      this.getStates(this._countryStateCitiesDetail.countryCode);
      this.stateChanged();
      this.isdataloading = false;
    }
    this.isdataloading = true;
  }

  getCountries() {
    abp.ui.setBusy();
    this._geoDataService.getCountries().pipe(
      finalize(() => abp.ui.clearBusy()),
    ).subscribe(result => {
      this.countryList = result;
      this.isdataloading = false;
    });
    this.isdataloading = true;
  }

  getStates(countryCode: string) {
    abp.ui.setBusy();
    this._geoDataService.getStates(countryCode).pipe(
      finalize(() => abp.ui.clearBusy()),
    ).subscribe(result => {
      this.stateList = result;
      this.isdataloading = false;
    });
    this.isdataloading = true;
  }

  countryChanged() {
    abp.ui.setBusy();
    this.countryStateCitiesDetail.stateCode = '';
    this.getStates(this.countryStateCitiesDetail.countryCode);
    var foundCountry = this.countryList.find(c => c.code == this.countryStateCitiesDetail.countryCode);
    console.log(foundCountry);
    this.countryStateCitiesDetail.city = '';
    this.cityList = [];
  }

  stateChanged() {
    abp.ui.setBusy();
    this._geoDataService.getCities(this.countryStateCitiesDetail.countryCode, this.countryStateCitiesDetail.stateCode).pipe(
      finalize(() => abp.ui.clearBusy()),
    ).subscribe(result => {
      this.cityList = result;
      var foundCity = this.cityList.find(c => c.code == this.countryStateCitiesDetail.city);
      this.countryStateCitiesDetail.city = foundCity ? foundCity.code : '';
      this.isdataloading = false;
    });
    this.isdataloading = true;
  }

}
