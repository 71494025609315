import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { SubscriptionStartType } from '@shared/service-proxies/service-proxies';
import { ChatSignalrService } from 'app/shared/layout/chat/chat-signalr.service';
import * as moment from 'moment';
import { AppComponentBase } from 'shared/common/app-component-base';
//import { SignalRHelper } from 'shared/helpers/SignalRHelper';
import { LinkedAccountsModalComponent } from '@app/shared/layout/linked-accounts-modal.component';
import { LoginAttemptsModalComponent } from '@app/shared/layout/login-attempts-modal.component';
import { ChangePasswordModalComponent } from '@app/shared/layout/profile/change-password-modal.component';
import { ChangeProfilePictureModalComponent } from '@app/shared/layout/profile/change-profile-picture-modal.component';
import { MySettingsModalComponent } from '@app/shared/layout/profile/my-settings-modal.component';
import { NotificationSettingsModalComponent } from '@app/shared/layout/notifications/notification-settings-modal.component';
import { UserNotificationHelper } from '@app/shared/layout/notifications/UserNotificationHelper';
import { Title } from '@angular/platform-browser';

@Component({
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent extends AppComponentBase implements OnInit, AfterViewInit {

  subscriptionStartType = SubscriptionStartType;
  theme: string;
  installationMode = true;

  @ViewChild('loginAttemptsModal') loginAttemptsModal: LoginAttemptsModalComponent;
  @ViewChild('linkedAccountsModal') linkedAccountsModal: LinkedAccountsModalComponent;
  @ViewChild('changePasswordModal') changePasswordModal: ChangePasswordModalComponent;
  @ViewChild('changeProfilePictureModal') changeProfilePictureModal: ChangeProfilePictureModalComponent;
  @ViewChild('mySettingsModal') mySettingsModal: MySettingsModalComponent;
  @ViewChild('notificationSettingsModal') notificationSettingsModal: NotificationSettingsModalComponent;
  @ViewChild('chatBarComponent') chatBarComponent;

  public constructor(
    private titleService: Title,
    injector: Injector,
    //private _chatSignalrService: ChatSignalrService,
    private _userNotificationHelper: UserNotificationHelper
  ) {
    super(injector);
  }
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  ngOnInit(): void {
    this._userNotificationHelper.settingsModal = this.notificationSettingsModal;
    this.theme = abp.setting.get('App.UiManagement.Theme').toLocaleLowerCase();
    this.installationMode = UrlHelper.isInstallUrl(location.href);

    this.registerModalOpenEvents();

    //if (this.appSession.application) {
    //  SignalRHelper.initSignalR(() => { this._chatSignalrService.init(); });
    //}
  }

  ngAfterViewInit(): void {
    //abp.signalr.autoConnect = false;       
  }

  subscriptionStatusBarVisible(): boolean {
    return this.appSession.tenantId > 0 &&
      (this.appSession.tenant.isInTrialPeriod ||
        this.subscriptionIsExpiringSoon());
  }

  subscriptionIsExpiringSoon(): boolean {
    if (this.appSession.tenant.subscriptionEndDateUtc) {
      return moment().utc().add(AppConsts.subscriptionExpireNootifyDayCount, 'days') >= moment(this.appSession.tenant.subscriptionEndDateUtc);
    }

    return false;
  }

  registerModalOpenEvents(): void {
    abp.event.on('app.show.loginAttemptsModal', () => {
      this.loginAttemptsModal.show();
    });

    abp.event.on('app.show.linkedAccountsModal', () => {
      this.linkedAccountsModal.show();
    });

    abp.event.on('app.show.changePasswordModal', () => {
      this.changePasswordModal.show();
    });

    abp.event.on('app.show.changeProfilePictureModal', () => {
      this.changeProfilePictureModal.show();
    });

    abp.event.on('app.show.mySettingsModal', () => {
      this.mySettingsModal.show();
    });
  }

  getRecentlyLinkedUsers(): void {
    abp.event.trigger('app.getRecentlyLinkedUsers');
  }

  onMySettingsModalSaved(): void {
    abp.event.trigger('app.onMySettingsModalSaved');
  }
}
