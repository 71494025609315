import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'programParticipation'
})
export class ProgramParticipationPipe implements PipeTransform {

  transform(progPartList: Array<any>, progId: any): any {
    let index = progPartList.findIndex((e1)=>{
      return e1.id == progId;
    })
    if(index > -1){
      return progPartList[index].description;
    }
  }

}
