import { Injector, ElementRef, Component, OnInit, AfterViewInit, ViewChild, HostBinding, Inject } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { LayoutRefService } from '@metronic/app/core/services/layout/layout-ref.service';
import { ThemesLayoutBaseComponent } from '@app/shared/layout/themes/themes-layout-base.component';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { AppConsts } from '@shared/AppConsts';
import { MenuAsideOffcanvasDirective } from '@metronic/app/core/directives/menu-aside-offcanvas.directive';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
    templateUrl: './theme7-layout.component.html',
    selector: 'theme7-layout',
    animations: [appModuleAnimation()]
})
export class Theme7LayoutComponent extends ThemesLayoutBaseComponent implements OnInit, AfterViewInit {

    @ViewChild('mHeader') mHeader: ElementRef;
    @ViewChild('mAsideLeft') mAsideLeft: ElementRef;
    @HostBinding('attr.mMenuAsideOffcanvas') mMenuAsideOffcanvas: MenuAsideOffcanvasDirective;

    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;
    defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/iQueueLogo.png'// + this.currentTheme.baseSettings.menu.asideSkin + '.svg';
    hideLeftMenu: boolean = false;
    marginleft = 100;

    constructor(
        injector: Injector,
        private router: Router,
        private layoutRefService: LayoutRefService,
        @Inject(DOCUMENT) private document: Document
    ) {
        super(injector);
        layoutRefService.LeftMenuVisible$.subscribe(flag => {
            this.hideLeftMenu = !flag;
            this.marginleft = flag ? 100 : 0;
            if(this.hideLeftMenu == false)
            {
                this.document.getElementById("m_ver_menu").style.display = '';
                this.document.getElementById("mainconatiner").classList.remove('mainPadding');
                this.document.getElementById("m_aside_left").setAttribute('style', '');
            }
        });
    }

    ngOnInit() {
        this.installationMode = UrlHelper.isInstallUrl(location.href);

    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.mMenuAsideOffcanvas = new MenuAsideOffcanvasDirective(this.mAsideLeft, this.router);
            this.mMenuAsideOffcanvas.ngAfterViewInit();
        });

        //this.layoutRefService.addElement('header', this.mHeader.nativeElement);
    }
}
