import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CodeName, CommonAddressDto, CountryDto, GeoDataServiceServiceProxy } from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit {

  cityList: CodeName[];
  countryList: CountryDto[];
  stateList: CodeName[];
  isdataloading: boolean;

  constructor(private _geoDataService: GeoDataServiceServiceProxy) {

  }

  ngOnInit() {
    this.addressDetail.address1='';
    this.addressDetail.address2='';
    this.addressDetail.countryCode ='';
    this.addressDetail.postalCode='';
    this.addressDetail.stateCode ='';
    this.addressDetail.city ='';
    this.getCountries();
  }

  private _addressDetail: CommonAddressDto;
  get addressDetail(): CommonAddressDto {
    return this._addressDetail;
  }

  @Input()
  set addressDetail(value: CommonAddressDto) {
    this._addressDetail = value;
    if (this._addressDetail != null) {
      this.getStates(this._addressDetail.countryCode);
      this.stateChanged();
      this.isdataloading = false;
    }
    this.isdataloading = true;
  }

  @Input() phoneFormat: string;
  @Output() phoneFormatChange: EventEmitter<string> = new EventEmitter<string>();

  getCountries() {
    abp.ui.setBusy();
    this._geoDataService.getCountries().pipe(
      finalize(() => abp.ui.clearBusy()),
    ).subscribe(result => {
      this.countryList = result;
      this.isdataloading = false;
    });
    this.isdataloading = true;
  }

  getStates(countryCode: string) {
    abp.ui.setBusy();
    this._geoDataService.getStates(countryCode).pipe(
      finalize(() => abp.ui.clearBusy()),
    ).subscribe(result => {
      this.stateList = result;
      this.isdataloading = false;
    });
    this.isdataloading = true;
  }

  countryChanged() {
    abp.ui.setBusy();
    this.addressDetail.stateCode = '';
    this.getStates(this.addressDetail.countryCode);
    var foundCountry = this.countryList.find(c => c.code == this.addressDetail.countryCode);
    this.phoneFormat = foundCountry.phoneFormat;
    this.phoneFormatChange.emit(foundCountry.phoneFormat);
    console.log(foundCountry);
    this.addressDetail.city = '';
    this.cityList = [];
  }

  stateChanged() {
    abp.ui.setBusy();
    this._geoDataService.getCities(this.addressDetail.countryCode, this.addressDetail.stateCode).pipe(
      finalize(() => abp.ui.clearBusy()),
    ).subscribe(result => {
      this.cityList = result;
      var foundCity = this.cityList.find(c => c.code == this.addressDetail.city);
      this.addressDetail.city = foundCity ? foundCity.code : '';
      this.isdataloading = false;
    });
    this.isdataloading = true;
  }
}
