import { Component, Injector, Inject, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AppAuthService } from '../common/auth/app-auth.service';
import { ThemesLayoutBaseComponent } from './themes/themes-layout-base.component';
import { Router } from '@angular/router';
import { UserServiceProxy, SessionServiceProxy } from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';
import { LayoutRefService } from '@metronic/app/core/services/layout/layout-ref.service';

@Component({
  templateUrl: './topbar.component.html',
  selector: 'topbar'
})
export class TopBarComponent extends ThemesLayoutBaseComponent implements OnInit {
  topMenus: MenuItem[];
  clientLogo: string;
  showClientLogo: boolean = true;
  userRole: string;
  defaultClientImage = '/assets/common/images/NoImage.png';

  constructor(
    private _sessionAppService: SessionServiceProxy,
    private _authService: AppAuthService, 
    private _userService: UserServiceProxy,
    private layoutRefService: LayoutRefService,
    private router: Router,
    injector: Injector) {
    super(injector);
    layoutRefService.LeftMenuVisible$.subscribe(flag => {
      if (flag) {
        this.clientLogo =  localStorage.getItem("brandLogo");
      }
      this.showClientLogo = flag;
    });
    layoutRefService.ClientLogoVisible$.subscribe(flag => {
      this.showClientLogo = flag;
    })
  }

  ngOnInit() {
    if(localStorage.getItem('userRole') == null){
      this.getUserRole();
      return;
    }
    else{
      this.setTopMenu();
    }
  }

  getUserRole() {
    if( localStorage.getItem('userRole') != null){
      return;
    }
    let token = abp.auth.getToken();
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
   
    var tokienInfo = [];
    tokienInfo = Object.values(JSON.parse(jsonPayload));

    let tenantId = tokienInfo[4] ;
    let userId = tokienInfo[0] ;

    console.log("tokienInfo");
    console.log(tokienInfo);
    let newTenantId = abp.session.tenantId;

    if(newTenantId == null)
    {
      localStorage.setItem('userId',userId);
      localStorage.setItem('userRole','superadmin');
      this.setTopMenu();
      return;
    }
    else
    {
      localStorage.setItem('userId',userId);
      localStorage.setItem('userRole',tokienInfo[3].toLowerCase());
      this.setTopMenu();
    }
    /*
    if(tokienInfo[3] == 'Admin'){
      localStorage.setItem('userId',userId);
      localStorage.setItem('userRole','superadmin');
      this.setTopMenu();
      return;
    }else{
      localStorage.setItem('tenantId',tenantId);
      localStorage.setItem('clientId',tenantId);
      localStorage.setItem('userId',userId);
    }
    */
    
    this._userService.getUserRole(parseInt(userId), parseInt(tenantId), tokienInfo[3]).pipe(
      finalize(() => abp.ui.clearBusy()),
    ).subscribe((result:any) => {    
      if(result.displayName.toLowerCase() == 'client / executive'){
          localStorage.setItem('userRole','client');
      }else {
          localStorage.setItem('userRole',result.displayName.toLowerCase());
      }
      this.setTopMenu();
    });
  }

  setTopMenu() {
    let brandLogo =  localStorage.getItem("brandLogo");
    if (brandLogo !== this.clientLogo) { 
      this.clientLogo = brandLogo;
    }
    this.userRole = localStorage.getItem('userRole');
    let homeLink = '';
    let profileLink = '';
    if (this.userRole === "superadmin") {
      abp.ui.setBusy(undefined, '', 1);
      homeLink = '/app/main/clientmanagement';
      profileLink = '/app/main/myuserprofile';
    }
    if (this.userRole === "admin" || this.userRole === "client") {
      homeLink = '/app/main/clientmanagement';
      //homeLink = '/app/main/activitydashboard';
      profileLink = '/app/main/myuserprofile';
    }
    /*
    if (this.userRole === "advisor") {
      homeLink = '/app/main/advisoractivity';
      profileLink = '/app/main/advisorprofile';
    }
    */
    this.topMenus = [
      {
        label: 'Home',
        routerLink: [homeLink]
      },
      {
        label: 'About',
        routerLink: ['/app/main/about']
      },
      {
        label: 'Contact',
        routerLink: ['/app/main/contact']
      },
      {
        label: this.appSession.user.userName,
        icon: 'pi pi-user',
        items: [
          {label: 'My Profile', routerLink: [profileLink] },
          {label: 'Change Password', command: (event) => { abp.event.trigger('app.show.changePasswordModal'); }},
          {label: 'Sign Out', command: (event) => 
            { 
              this._authService.logout();
              localStorage.clear();
            }}
        ]
      }
    ];
  }

  editclient(): void {
    if(this.userRole=="superadmin"){
      let clientId = localStorage.getItem("clientId");
      this.router.navigate(['/app/main/createclient'], { queryParams: { clientId: clientId } });
    }    
  }
}
