import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'speakerStatus'
})
export class SpeakerStatusPipe implements PipeTransform {

  transform(speakerStatusList: Array<any>, speakerId:number): any {
    let index = speakerStatusList.findIndex((e1)=>{
      return e1.id == speakerId;
    })
    if(index > -1){
      return speakerStatusList[index].description;
    }
    return null;
  }

}
