import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'language'
})
export class LanguagePipe implements PipeTransform {

  transform(languageList: Array<any>, languageId:number): any {
    let index = languageList.findIndex((e1)=>{
      return e1.id == languageId;
    })
    if(index > -1){
      return languageList[index].description;
    }
    return null;
  }

}
